import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef, Getter } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetGroupByResourceTotal,
  usePostResourcePlannerChange,
} from 'src/apis/resourcePlannerAPI';
import { IResourcePlannerPeriodValueString } from 'src/apis/types/resourcePlannerAPI';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { useIsSidePanelOpen } from 'src/components/layout/SidePanel';
import { CircularProgress } from 'src/components/mui-components';
import { Deck, THorizontalAlignment } from 'src/components/ui-components';
import { useFilterStore } from 'src/stores/FilterStore';
import { useResourcePlannerStore } from 'src/stores/ResourcePlannerStore/ResourcePlannerStore';
import {
  getDateStringFromSiteLocale,
  getDateWithZeroOffsetFromDateString,
  getLanguageFromSiteLocale,
} from 'src/utils/date';
import { translationAnyText } from 'src/utils/translation';
import { TCollapseExpandState } from '../../helper/expandedRow';
import generatePeriodLabel from '../../helper/generatePeriodLabel';
import { getFixedColumnTitleIdentifier } from '../../helper/getFixedColumnTooltip';
import { getTranslatedText } from '../../helper/getTranslatedText';
import { reloadRpTables } from '../../helper/reloadRpTables';
import { useGetResourceView } from '../../hooks';
import { RPRow, RTColumn, RTRow } from '../../types/resourcePlanner';
import ExcessiveOrNoData from '../ExcessiveOrNoData';
import { GeneralColumnRenderer } from '../GeneralColumnRenderer';
import TotalColumnRenderer from '../TotalColumnRenderer';
import { FIXED_COLUMNS, TAKE } from './ResourceTableGroupedByEmployee.constants';
import styles from './ResourceTableGroupedByEmployee.module.scss';
import { ResourceTableGroupedByEmployeeTable } from './ResourceTableGroupedByEmployeeTable';

export const ResourceTableGroupedByEmployee = () => {
  const { t } = useTranslation('resourcePlanner');
  const qc = useQueryClient();

  const { currentLanguage } = useGetCurrentLanguage();
  const siteLocale = useGetLocale();
  const siteLanguage = getLanguageFromSiteLocale(siteLocale);

  const sidePanelIsOpen = useIsSidePanelOpen();
  const { filterQueryObj } = useFilterStore();
  const filterQueryObjString = useRef<string>(JSON.stringify(filterQueryObj));
  const { selectedViewOptions } = useResourcePlannerStore();

  const [expandedRows, setExpandedRows] = useState<TCollapseExpandState[]>([]);

  const {
    children,
    fetchNextPage,
    isEmpty,
    isFetching,
    isFetchingNextPage,
    isNextPageAvailable,
    isRefetching,
    partial,
    periods,
    responseType,
  } = useGetResourceView({
    expandedRows,
    params: selectedViewOptions,
    selectedFilterList: filterQueryObj,
    take: TAKE,
  });

  const { data: totalRow } = useGetGroupByResourceTotal({
    params: selectedViewOptions,
    selectedFilterList: filterQueryObj,
  });

  const { mutate: postChange } = usePostResourcePlannerChange();
  const unitType = selectedViewOptions['unit-types'];

  const columns = useMemo<ColumnDef<RPRow>[]>(
    () => [
      {
        id: 'expandCollapseAllRows',
        accessorKey: 'name',
        header: () => t('ColumnLabelName'),
        cell: ({ getValue }) => getValue() as string,
      },
      ...FIXED_COLUMNS.map((fixedColumn) => ({
        id: fixedColumn.identifier,
        accessorKey: fixedColumn.identifier,
        meta: {
          editable: false,
          format: fixedColumn?.format,
          alignment: fixedColumn?.alignment as THorizontalAlignment,
          dividerBorderLeft: fixedColumn.identifier === 'budget',
          title: translationAnyText(t, getFixedColumnTitleIdentifier(fixedColumn.identifier)),
        },
        header: () => getTranslatedText(t, unitType, fixedColumn.identifier, 'EmployeeView'),
        cell: ({
          row,
          column,
          getValue,
        }: {
          row: RTRow;
          column: RTColumn;
          getValue: Getter<string>;
        }) =>
          GeneralColumnRenderer({
            row,
            id: column.columnDef.id,
            format: column.columnDef.meta?.format,
            value: getValue(),
          }),
      })),
      ...periods.map((periodColumn, index) => ({
        id: `periodCol_${periodColumn.identifier}`,
        accessorFn: (row: RPRow) => row.values[periodColumn.identifier],
        meta: {
          editable: true,
          alignment: 'right' as THorizontalAlignment,
          startsAt: periodColumn.startsAt,
          endsAt: periodColumn.endsAt,
          dividerBorderLeft: index === 0,
          title:
            selectedViewOptions['period-types'] !== 'day'
              ? `${getDateStringFromSiteLocale(
                  getDateWithZeroOffsetFromDateString(periodColumn.startsAt),
                  siteLanguage,
                )} - ${getDateStringFromSiteLocale(
                  getDateWithZeroOffsetFromDateString(periodColumn.endsAt),
                  siteLanguage,
                )}`
              : '',
        },
        header: () =>
          generatePeriodLabel(
            periodColumn.identifier,
            selectedViewOptions['period-types'],
            currentLanguage,
            t,
          ),
        cell: ({ getValue }: { getValue: Getter<IResourcePlannerPeriodValueString> }) =>
          getValue().displayValue,
      })),
      {
        id: 'total',
        accessorKey: 'total',
        meta: {
          editable: true,
          alignment: 'right' as THorizontalAlignment,
          type: 'total',
        },
        header: () => t('ColumnLabelTotal'),
        cell: ({ row }) =>
          TotalColumnRenderer({
            displayValue: row.original.total?.displayValue,
            unitType,
          }),
      },
    ],
    [selectedViewOptions, periods, t, currentLanguage, unitType, siteLanguage],
  );

  const onCellValueChange = useCallback(
    (row: RTRow, column: RTColumn, value: string) => {
      const { resourceId, workItemId } = row.original;
      const startsAt = column.columnDef.meta?.startsAt!;
      const endsAt = column.columnDef.meta?.endsAt!;

      postChange(
        {
          resourceId,
          workItemId,
          unitType,
          value,
          startsAt,
          endsAt,
        },
        {
          onSettled: () => {
            reloadRpTables(qc);
          },
        },
      );
    },
    [postChange, qc, unitType],
  );

  const handleOnToggleRow = useCallback(
    (row: RTRow) => {
      const toggledRow = expandedRows.find((item) => item.tableRowId === row.id);
      setExpandedRows((prev) => {
        if (toggledRow) {
          toggledRow.isExpanded = !row.getIsExpanded();
          return prev.filter((r) => r.isExpanded);
        }
        return [
          ...prev,
          {
            canExpand: row.original.canExpand,
            isExpanded: !row.getIsExpanded(),
            originalId: row.original.sourceReferenceId,
            resourceIdRoot: row.original.resourceSourceReferenceId,
            tableRowId: row.id,
            type: row.original.type,
          },
        ];
      });
      row.toggleExpanded();
    },
    [expandedRows],
  );

  const rowsThatAreLoading = useMemo(
    () =>
      partial
        .filter((item) => item.isFetching)
        .map((item) => `${item.originalId}_${item.resourceIdRoot}`),
    [partial],
  );

  useEffect(() => {
    const stringedFilterQueryObj = JSON.stringify(filterQueryObj);
    if (filterQueryObjString.current === stringedFilterQueryObj) {
      return;
    }
    filterQueryObjString.current = JSON.stringify(filterQueryObj);
    setExpandedRows([]);
  }, [filterQueryObj]);

  if (responseType === 'ExcessiveData' || (isEmpty && responseType === 'NoData')) {
    return <ExcessiveOrNoData type={responseType} />;
  }

  return (
    <>
      {isFetching && !isFetchingNextPage && !isRefetching ? (
        <Deck.Item sidePanelIsOpen={sidePanelIsOpen}>
          <Stack className={styles.initialfetchSpinner}>
            <CircularProgress />
          </Stack>
        </Deck.Item>
      ) : null}
      <Deck.Item hasOverflow>
        <ResourceTableGroupedByEmployeeTable
          columns={columns}
          data={children}
          fetchNextPage={!isFetching && isNextPageAvailable ? fetchNextPage : () => {}}
          handleOnToggleRow={handleOnToggleRow}
          onCellValueChange={onCellValueChange}
          periods={periods}
          rowsThatAreLoading={rowsThatAreLoading}
          selectedViewOptions={selectedViewOptions}
          totalRow={totalRow ?? {}}
          unitType={unitType}
        />
      </Deck.Item>
      {isFetchingNextPage ? (
        <Deck.Item sidePanelIsOpen={sidePanelIsOpen}>
          <Stack className={styles.fetchNextPageSpinner}>
            <CircularProgress />
          </Stack>
        </Deck.Item>
      ) : null}
    </>
  );
};
