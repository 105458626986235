import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import kebabCase from 'lodash.kebabcase';
import { TFilterType } from 'src/apis/types/filterListAPI';
import { getFetch } from 'src/utils/fetcher';
import { FilterValues, ISavedViewResponse, ViewOptionsValues } from '../savedViewAPI';
import { GET_SAVED_VIEW_KEY, GET_SAVED_VIEW_MSW_STATUS_KEY } from './getSavedViewAPI.constants';

const GET_SAVED_VIEW_URL_PATH = '/api/v2/filter-view';

const viewOptionsFilterTypes: TFilterType[] = ['DateRange', 'SingleSelect', 'Textbox'];

const transformViewOptions = (viewOptions: any) =>
  viewOptions.reduce((acc: any, option: any) => {
    if (option.name === 'DateRange' && typeof option.value === 'object' && option.value !== null) {
      const { periodStartsAt, periodEndsAt } = option.value;
      if (periodStartsAt)
        acc[kebabCase('periodStartsAt')] = format(parseISO(periodStartsAt), 'yyyy-MM-dd');
      if (periodEndsAt)
        acc[kebabCase('periodEndsAt')] = format(parseISO(periodEndsAt), 'yyyy-MM-dd');
    } else {
      const value = option.value?.[0]?.value ?? option.value;
      acc[kebabCase(option.name)] = value;
    }
    return acc;
  }, {});

const getSavedView = (pageIdentifier: string): Promise<ISavedViewResponse> =>
  getFetch({
    path: `${GET_SAVED_VIEW_URL_PATH}/${pageIdentifier}`,
    key: GET_SAVED_VIEW_MSW_STATUS_KEY,
  });

export const useGetSavedView = (pageIdentifier: string) =>
  useQuery([GET_SAVED_VIEW_KEY, pageIdentifier], () => getSavedView(pageIdentifier), {
    enabled: !!pageIdentifier,
    select: (data) => {
      // Split into filterValues and viewOptions
      const filteredSaveView = data?.entities
        ? data.entities.map(({ properties }) => {
            const viewOptions: ViewOptionsValues[] = [];
            const filterValues = properties.filterValues
              ?.map((filterValue) => {
                if (viewOptionsFilterTypes.includes(filterValue.filterType)) {
                  viewOptions.push(filterValue as ViewOptionsValues);
                  return null;
                }
                return {
                  ...filterValue,
                } as unknown as FilterValues;
              })
              .filter(Boolean);

            return {
              ...properties,
              filterValues,
              viewOptions,
            };
          })
        : [];

      // Convert key to label
      const savedView = filteredSaveView.map((view) => ({
        ...view,
        filterValues: view?.filterValues?.map((filterValue) => ({
          ...filterValue,
          value: filterValue?.value?.map((val: any) => {
            const { key, ...rest } = val;
            return {
              ...rest,
              ...(key !== null && { label: key }),
            } as unknown as FilterValues;
          }),
        })),
      }));

      // Convert DateRange
      const updatedViewOptionsList = savedView.map((view) => ({
        ...view,
        viewOptions: transformViewOptions(view.viewOptions),
        filterValues: view.filterValues.map((filterValue: any) => ({
          ...filterValue,
          value: filterValue.value || [],
        })),
      }));

      return updatedViewOptionsList;
    },
  });
