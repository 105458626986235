import { Card, CardContent, FormControl, Grid, Stack, Typography } from '@mui/material';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ToastifyAlert,
} from 'src/components/mui-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getDateStringFromSiteLocale } from 'src/utils/date';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { formatTime } from 'src/utils/time';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Dispatch, SetStateAction } from 'react';
import { IMoveHours, IProjectOptions } from 'src/apis/moveHoursAPI/types';
import { usePostRegistrationSplit } from 'src/apis/moveHoursAPI';
import { convertNumber, formatNumber, validateNumberInput } from 'src/utils/number';
import { TaskAutoComplete } from './TaskAutoComplete';

interface IFormData {
  project: {
    value: number;
    name: string;
  } | null;
  task: {
    value: number;
    name: string;
  } | null;
  hours: string;
  comment: string;
  additionalText: string;
}
interface FormValues {
  split: Array<IFormData>;
}

interface ISplitDialog {
  showSplit: boolean;
  setShowSplit: Dispatch<SetStateAction<boolean>>;
  splitDialogData: IMoveHours & { additionalCommentLabel: string };
  projectOptions: IProjectOptions[];
  isCommentMandatory: boolean;
}

export const SplitDialog = ({
  showSplit,
  setShowSplit,
  splitDialogData,
  projectOptions,
  isCommentMandatory,
}: ISplitDialog) => {
  const { t } = useTranslation('moveHours');
  const siteLocale = useGetLocale();

  const { mutate: postRegistrationsSplit } = usePostRegistrationSplit();

  const {
    projectId,
    projectName,
    wbs,
    taskId,
    taskName,
    date,
    registeredHours,
    comment,
    additionalText,
    additionalCommentLabel,
    timeRegistrationId,
  } = splitDialogData;

  const formSchema = z.object({
    split: z.array(
      z.object({
        project: z.object({
          name: z.string().min(1),
          value: z.number(),
        }),
        task: z.object({
          name: z.string().min(1),
          value: z.number(),
        }),
        hours: z
          .string()
          .min(1)
          .refine(
            (value) => {
              const { status } = validateNumberInput(value, t('DecimalFormatValidation'));
              return status === 'success';
            },
            {
              message: t('DecimalFormatValidation'),
            },
          ),
        comment: isCommentMandatory ? z.string().min(1) : z.string().optional(),
        additionalText: z.string(),
      }),
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      split: [
        {
          project: {
            value: projectId,
            name: projectName,
          },
          task: {
            value: taskId,
            name: taskName,
          },
          hours: '',
          comment,
          additionalText,
        },
        {
          project: null,
          task: null,
          hours: '',
          comment: '',
          additionalText: '',
        },
      ],
    },
    resolver: zodResolver(formSchema),
  });

  const { fields } = useFieldArray({ name: 'split', control });

  const handleOk = async (data: FormValues) => {
    const { split } = data;

    const formattedSplit = split.map((item) => {
      const formattedItem: any = {
        targetTaskId: item.task?.value,
        hours: convertNumber(item.hours, siteLocale),
        comment: item.comment,
      };

      if (additionalCommentLabel) {
        formattedItem.additionalText = item.additionalText;
      }

      return formattedItem;
    });

    postRegistrationsSplit(
      { timeRegistrationId, postModel: formattedSplit },
      {
        onError: (error) => {
          toast.error(<ToastifyAlert description={t(`errors.${(error as Error).message}`)} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
        onSuccess: () => {
          toast.success(<ToastifyAlert description={t('SplitSuccessToast')} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
      },
    );
    reset();
    setShowSplit(false);
  };

  const handleBlur = async (index: number) => {
    const hoursValue = getValues(`split.${index}.hours`);
    const { status, value } = validateNumberInput(
      hoursValue,
      t('errors.UseDecimalFormatValidationFeedback'),
    );

    if (status === 'error') {
      setError(`split.${index}.hours`, {
        type: 'manual',
        message: value,
      });
      return;
    }
    clearErrors(`split.${index}.hours`);

    const hours = convertNumber(getValues(`split.${index}.hours`), siteLocale);
    const totalHours = registeredHours;

    if (index === 0) {
      if (hours >= totalHours) {
        setValue('split.0.hours', formatNumber(totalHours, siteLocale));
        setValue('split.1.hours', formatNumber(0, siteLocale));
      } else {
        setValue('split.0.hours', formatNumber(hours, siteLocale));
        setValue('split.1.hours', formatNumber(totalHours - hours, siteLocale));
      }
    } else if (index === 1) {
      if (hours >= totalHours) {
        setValue('split.0.hours', formatNumber(0, siteLocale));
        setValue('split.1.hours', formatNumber(totalHours, siteLocale));
      } else {
        setValue('split.0.hours', formatNumber(totalHours - hours, siteLocale));
        setValue('split.1.hours', formatNumber(hours, siteLocale));
      }
    }
  };

  return (
    <Dialog
      open={!!showSplit}
      onClose={() => setShowSplit(false)}
      aria-labelledby="dialog-time-registration"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="dialog-time-registration">{t('SplitTimeRegistrationText')}</DialogTitle>
      <DialogContent>
        <Stack gap={1.5}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderProjectName')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>{projectName}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderWbs')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>{wbs}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderTaskName')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>{taskName}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderDate')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>
                    {getDateStringFromSiteLocale(new Date(date), siteLocale)}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TotalHoursText')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>
                    {formatTime(registeredHours, siteLocale)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Stack gap={1}>
            <Typography fontWeight={600} component="h3">
              {t('DistributeBetweenTasksText')}
            </Typography>
            <Grid container>
              {fields.map((field, index) => {
                const projectValue = watch(`split.${index}.project`);
                return (
                  <Grid item xs={6} key={field.id}>
                    <Card variant="outlined">
                      <CardContent>
                        <Stack gap={2}>
                          <FormControl fullWidth>
                            <Controller
                              name={`split.${index}.project`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  size="small"
                                  label={t('SelectProjectText')}
                                  placeholder=""
                                  options={projectOptions}
                                  getOptionLabel={(option) =>
                                    typeof option === 'object' ? option.name : option
                                  }
                                  isOptionEqualToValue={(o, v) => o.value === v.value}
                                  renderOption={(props, option, state) => (
                                    <li
                                      {...props}
                                      data-automation-id={`${option.name}Item`}
                                      key={`${option.name}-${state.index}`}
                                    >
                                      <HighlightMatchingText
                                        name={option.name}
                                        matchName={state.inputValue}
                                      />
                                    </li>
                                  )}
                                  onChange={(event, item) => {
                                    onChange(item || null);
                                    setValue(`split.${index}.task`, {
                                      value: 0,
                                      name: '',
                                    });
                                  }}
                                  error={!!errors.split?.[index]?.project}
                                  value={value}
                                  data-automation-id="SplitProjectNameDropdown"
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TaskAutoComplete
                              projectId={projectValue?.value || 0}
                              control={control}
                              index={index}
                              error={!!errors.split?.[index]?.task}
                            />
                          </FormControl>
                          <TextField
                            data-automation-id={`Hours${index}`}
                            label={t('HoursText')}
                            ariaLabel="hours-input"
                            variant="outlined"
                            size="small"
                            required
                            sx={{ width: '50%' }}
                            placeholder="0,00"
                            error={!!errors.split?.[index]?.hours}
                            value={watch(`split.${index}.hours`)}
                            {...register(`split.${index}.hours`)}
                            onBlur={() => handleBlur(index)}
                          />
                          <TextField
                            data-automation-id={`Comment${index}`}
                            label={t('TableHeaderComment')}
                            ariaLabel="comment-input"
                            variant="outlined"
                            size="small"
                            error={!!errors.split?.[index]?.comment}
                            {...register(`split.${index}.comment`)}
                          />
                          {additionalCommentLabel && (
                            <TextField
                              data-automation-id={`AdditionalText${index}`}
                              label={additionalCommentLabel}
                              ariaLabel="additional-input"
                              variant="outlined"
                              size="small"
                              multiline
                              {...register(`split.${index}.additionalText`)}
                            />
                          )}
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            reset();
            setShowSplit(false);
          }}
        >
          {t('CancelButton')}
        </Button>
        <Button variant="contained" onClick={handleSubmit(handleOk)} data-automation-id="OkButton">
          {t('OkButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
