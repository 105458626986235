import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { useGetSavedView } from 'src/apis/savedViewAPI';
import { FilterPanelLayout } from 'src/components/layout/FilterPanelLayout';
import { FilterSection } from 'src/components/layout/FilterPanelLayout/components/MainContainer/components/FilterSection';
import {
  IOptionsChangeParameters,
  TViewOptions,
} from 'src/components/layout/FilterPanelLayout/types';
import { Deck } from 'src/components/ui-components';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { Table } from './components/Table';
import { SPViewOptionsStateKey } from './localStorageKeys';

export const SearchProjects = () => {
  const pageIdentifier = useGetCurrentPageIdentifier();

  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);
  const { data } = useGetSavedView(pageIdentifier);
  const savedView = useMemo(() => data || [], [data]);

  const [activeView, setActiveView] = useState<string>('');
  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptions | undefined>(undefined);

  useEffect(() => {
    // Reset activeView to an empty string when the component mounts
    setActiveView('');
  }, []);

  const setViewOptions = useCallback(() => {
    const viewOptions = savedView.find((v: any) => v.filterViewId === activeView)?.viewOptions;
    setChangedViewOptions(viewOptions);
  }, [activeView, savedView]);

  useEffect(() => {
    setViewOptions();
  }, [activeView, setViewOptions]);

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    localStorage.setItem(SPViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  const onViewOptionsChange = (optionItems: IOptionsChangeParameters[]) => {
    const options = optionItems.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...options);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  // To fix retrieve previous pageIdentifier from store
  if (pageIdentifier !== 'DesignSevenSearchProject') {
    return null;
  }

  return (
    <ResponseHandler isLoading={isLoading} isError={isError} isEmpty={filterList.length <= 0}>
      <FilterPanelLayout
        filterList={filterList}
        savedViewsList={savedView}
        activeView={activeView}
        setActiveView={setActiveView}
        changedViewOptions={changedViewOptions}
        setViewOptions={setViewOptions}
        key="SearchProjectsFilterPanelLayout"
      >
        <Deck.Item>
          <Table
            selectedViewOptions={changedViewOptions}
            onViewOptionsChange={onViewOptionsChange}
            filterSection={
              <FilterSection
                changedViewOptions={changedViewOptions}
                onViewOptionsChange={onViewOptionsChange}
                key="SearchProjectsFilterSection"
              />
            }
          />
        </Deck.Item>
      </FilterPanelLayout>
    </ResponseHandler>
  );
};
