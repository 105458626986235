import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { GET_SAVED_VIEW_KEY } from '../get/getSavedViewAPI.constants';
import { ISavedViewBodyModel } from '../savedViewAPI';

const POST_SAVED_VIEW_URL_PATH = '/api/v2/filter-view';
export const POST_SAVED_VIEW_MSW_STATUS_KEY = 'postSavedViewAPI';

const postSavedView = ({
  pageIdentifier,
  postModel,
}: {
  pageIdentifier: string;
  postModel: ISavedViewBodyModel;
}) =>
  postFetch({
    path: `${POST_SAVED_VIEW_URL_PATH}/${pageIdentifier}`,
    key: POST_SAVED_VIEW_MSW_STATUS_KEY,
    body: JSON.stringify(postModel),
  });

export const usePostSavedView = () => {
  const queryClient = useQueryClient();

  return useMutation(postSavedView, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_SAVED_VIEW_KEY]);
    },
  });
};
