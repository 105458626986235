import { ISavedViewResponse } from 'src/apis/savedViewAPI';

export const OldSavedViews = [
  {
    createdDate: '2024-10-21T08:39:16.411Z',
    filters: {},
    id: '0cc9568d-6aad-49db-9c43-51f8edf98fb8',
    isDefault: false,
    period: 'this_and_next_three_weeks',
    title: 'Project view',
    updatedDate: '2024-11-01T04:00:22.016Z',
    viewOptions: {
      'grouped-by': 'group-by-work-item',
      'reporting-types': 'availability',
      'period-types': 'day',
      'unit-types': 'hours',
    },
  },
  {
    createdDate: '2024-11-01T04:00:33.481Z',
    filters: {},
    id: '47afbbd9-d1be-4d75-8088-b5ef70f98de8',
    isDefault: false,
    period: 'this_and_next_three_weeks',
    title: 'Resource view',
    updatedDate: '2024-11-01T04:00:37.358Z',
    viewOptions: {
      'grouped-by': 'group-by-resource',
      'reporting-types': 'availability',
      'period-types': 'day',
      'unit-types': 'hours',
    },
  },
];

const NewSavedViews: ISavedViewResponse = {
  entities: [
    {
      properties: {
        created: new Date('2024-10-21T08:39:16.411Z'),
        filterValues: [
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'grouped-by',
            value: [{ key: '', value: 'group-by-work-item' }],
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'reporting-types',
            value: [{ key: '', value: 'availability' }],
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'period-types',
            value: [{ key: '', value: 'day' }],
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'unit-types',
            value: [{ key: '', value: 'hours' }],
          },
        ],
        filterViewId: '0',
        isDefault: false,
        lastModified: new Date('2024-11-01T04:00:22.016Z'),
        name: 'Project View',
      },
    },
    {
      properties: {
        created: new Date('2024-11-01T04:00:33.481Z'),
        filterValues: [
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'grouped-by',
            value: [{ key: '', value: 'group-by-resource' }],
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'reporting-types',
            value: [{ key: '', value: 'availability' }],
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'period-types',
            value: [{ key: '', value: 'day' }],
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'unit-types',
            value: [{ key: '', value: 'hours' }],
          },
        ],
        filterViewId: '1',
        isDefault: false,
        lastModified: new Date('2024-11-01T04:00:37.358Z'),
        name: 'Resource View',
      },
    },
  ],
  links: [
    {
      href: 'http://localhost/tlp/api/v2/filter-view',
      rel: 'self',
    },
  ],
};

export default NewSavedViews;
