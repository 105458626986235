import { format, parse } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { useGetResourceViewOptions } from 'src/apis/resourcePlannerAPI';
import { useGetSavedView } from 'src/apis/savedViewAPI';
import { ViewOptions } from 'src/components/layout/FilterLayout/components/MainContainer/components/ViewOptions';
import { TViewOptionsChangeParameters } from 'src/components/layout/FilterLayout/types';
import { FilterPanelLayout } from 'src/components/layout/FilterPanelLayout';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { TViewOptions } from 'src/screens/ResourcePlanner/types/resourcePlanner';
import { ResourcePlannerStore } from 'src/stores/ResourcePlannerStore';
import { PeriodPicker } from './components/PeriodPicker';
import { ResourceTableGroupedByEmployee } from './components/ResourceTableGroupedByEmployee';
import { ResourceTableGroupedByProject } from './components/ResourceTableGroupedByProject';
import { getEndDateWithPeriod } from './helper/getEndDateWithPeriod';
import { useSignalRConnection } from './hooks';
import { TViewOptionKey, ViewType } from './types/resourcePlanner';

export const ResourcePlanner = () => {
  useSignalRConnection();

  const {
    filterList,
    isError: filterIsError,
    isFetching: filterIsFetching,
  } = useGetFilterAPI('NewResourcePlanner');
  const {
    data,
    isError: savedViewIsError,
    isFetching: savedViewIsFetching,
  } = useGetSavedView('NewResourcePlanner');

  const [activeViewId, setActiveViewId] = useState('');
  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptions | undefined>(undefined);

  const savedView = useMemo(() => data || [], [data]);
  const activeView = useMemo(
    () => savedView.find((v) => v.filterViewId === activeViewId),
    [activeViewId, savedView],
  );
  const viewOptions = useMemo(() => activeView?.viewOptions, [activeView?.viewOptions]);
  const endDate = useMemo(() => getEndDateWithPeriod(activeView?.period), [activeView?.period]);

  const [initialDateEnd, setInitialDateEnd] = useState(endDate);
  const [initialDateStart, setInitialDateStart] = useState(new Date());

  const { fields: initialFields } = useGetResourceViewOptions();
  const fields = useMemo(
    () =>
      initialFields.map((field) => ({
        ...field,
        value: changedViewOptions?.[field.name as TViewOptionKey] ?? field.value,
        options: field.options.map((option) => ({
          ...option,
        })),
      })),
    [changedViewOptions, initialFields],
  );
  const groupedBy = useMemo(
    () => fields.find(({ name }) => name === 'grouped-by')?.value as ViewType,
    [fields],
  );
  const selectedViewOptions = useMemo(
    () =>
      fields.reduce<TViewOptions>(
        (acc, { name, value }) => {
          if (value) {
            acc[name as keyof TViewOptions] = value;
          }
          return acc;
        },
        {
          'period-starts-at': format(initialDateStart, 'yyyy-MM-dd'),
          'period-ends-at': format(initialDateEnd, 'yyyy-MM-dd'),
        },
      ),
    [fields, initialDateEnd, initialDateStart],
  );

  const onViewOptionsChange = (items: TViewOptionsChangeParameters[]) => {
    const options = items.map((item) => {
      if (item.name === 'period-starts-at') {
        setInitialDateStart(parse(item.value, 'yyyy-MM-dd', new Date()));
      }
      if (item.name === 'period-ends-at') {
        setInitialDateEnd(parse(item.value, 'yyyy-MM-dd', new Date()));
      }
      return { [item.name]: item.value };
    });
    const optionsToObject = Object.assign({}, ...options);
    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  useEffect(() => {
    setChangedViewOptions(viewOptions);
  }, [activeViewId, viewOptions]);

  return (
    <ResponseHandler
      isEmpty={filterList.length <= 0}
      isError={filterIsError || savedViewIsError}
      isLoading={filterIsFetching || savedViewIsFetching}
    >
      <FilterPanelLayout
        activeView={activeViewId}
        changedViewOptions={changedViewOptions}
        filterList={filterList}
        savedViewsList={savedView}
        setActiveView={setActiveViewId}
      >
        <ResourcePlannerStore
          value={{
            dateEnd: initialDateEnd,
            filterList,
            rpLoading: false,
            selectedViewOptions,
          }}
        >
          <ViewOptions
            viewOptionsChange={onViewOptionsChange}
            viewOptionsFields={fields}
            viewOptionsLeftFilter={
              <PeriodPicker
                initialDateEnd={initialDateEnd}
                initialDateStart={initialDateStart}
                onChange={onViewOptionsChange}
              />
            }
          />
          {groupedBy === 'group-by-resource' && <ResourceTableGroupedByEmployee />}
          {groupedBy === 'group-by-work-item' && <ResourceTableGroupedByProject />}
        </ResourcePlannerStore>
      </FilterPanelLayout>
    </ResponseHandler>
  );
};
