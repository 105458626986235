import { addMonths } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { useGetRevenueForecastViewOptions } from 'src/apis/revenueForecastAPI';
import { FilterLayout } from 'src/components/layout/FilterLayout';
import {
  IViewOptionsField,
  TViewOptionsChangeParameters,
} from 'src/components/layout/FilterLayout/types';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { safeParseJson } from 'src/utils/object';

import { MonthPickerDates } from 'src/components/ui-components/MonthPicker';
import { IFilterProps, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import { PeriodPicker, Table } from './components';
import {
  revenueForecastSelectedFilterListStateKey,
  revenueForecastViewOptionsStateKey,
} from './localStorageKeys';

// TODO: persist mui table state (density, column width, column visibility, sort)
// TODO: styling of adjusting column width (Baiba on it)

export const RevenueForecast = () => {
  const { fields: initialFields } = useGetRevenueForecastViewOptions();

  const pageIdentifier = useGetCurrentPageIdentifier();
  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);

  const localViewOptions = localStorage.getItem(revenueForecastViewOptionsStateKey);
  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptionsChangeParameters>(
    localViewOptions ? safeParseJson(localViewOptions) || {} : {},
  );

  const [monthRange, setMonthRange] = useState<MonthPickerDates>({
    from: changedViewOptions['period-starts-at']
      ? new Date(changedViewOptions['period-starts-at'])
      : addMonths(new Date(), -3),
    to: changedViewOptions['period-ends-at']
      ? new Date(changedViewOptions['period-ends-at'])
      : addMonths(new Date(), 3),
  });

  const fields: Array<IViewOptionsField> = useMemo(
    () =>
      initialFields.map((field: IViewOptionsField) => {
        const value = (() => {
          if (changedViewOptions && changedViewOptions[field.name]) {
            return changedViewOptions[field.name];
          }
          return field.value;
        })();

        return {
          ...field,
          value,
          options: field?.options?.map((option) => ({
            ...option,
          })),
        };
      }),
    [initialFields, changedViewOptions],
  );

  useEffect(() => {
    localStorage.setItem(revenueForecastViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  const onViewOptionsChange = (optionItems: TViewOptionsChangeParameters[]) => {
    const options = optionItems.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...options);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const getSelectedFilterFromLocalStorage = (): Record<TFilterID, IFilterProps> => {
    const filterFromLocalStorage: Record<TFilterID, IFilterProps> =
      safeParseJson(localStorage.getItem(revenueForecastSelectedFilterListStateKey) || '') || {};
    if (filterList.length > 0) {
      const flatMapped = filterList.flatMap((x) => x.filterItems).map((x) => x?.name);
      Object.keys(filterFromLocalStorage).forEach((key) => {
        if (flatMapped.indexOf(key) === -1) {
          delete filterFromLocalStorage[key];
        }
      });
    }
    return filterFromLocalStorage;
  };

  return (
    <ResponseHandler isLoading={isLoading} isEmpty={filterList.length <= 0} isError={isError}>
      <FilterLayout
        filterList={filterList}
        selectedFilterList={getSelectedFilterFromLocalStorage()}
        viewOptionsLeftFilter={
          <PeriodPicker
            initialMonthRange={monthRange}
            setMonthRange={setMonthRange}
            onChange={onViewOptionsChange}
          />
        }
        viewOptionsFields={fields}
        viewOptionsChange={onViewOptionsChange}
        localStorageNamePrefix="revenue-forecast"
      >
        <Table selectedViewOptions={changedViewOptions} />
      </FilterLayout>
    </ResponseHandler>
  );
};

export default RevenueForecast;
