import { Flex, Heading, Text, Icon, Box, ButtonGhost } from 'src/components/ui-components';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import { useFilterDispatch } from 'src/stores/FilterStore';
import { TViewOptionsChangeParameters } from 'src/components/layout/FilterLayout/types';
import styles from './TableNoData.module.scss';

export const TableNoData = ({
  onViewOptionsChange,
}: {
  onViewOptionsChange: (item: TViewOptionsChangeParameters[]) => void;
}) => {
  const { t } = useTranslation('resourcePlanner');

  const iconName = 'info';
  const dispatch = useFilterDispatch();

  return (
    <div className={styles.customPadding}>
      <Flex horizontalAlignment="center">
        <Box size="small">
          <Box.Item compact={false}>
            <Flex direction="column" verticalAlignment="top">
              <Flex gap="large">
                <Icon iconName={iconName} size="normal" />
                <Heading level={2} as={2}>
                  {translationAnyText(t, 'HeadingNoResults')}
                </Heading>
              </Flex>
              <Text>{translationAnyText(t, 'DescriptionNoResults')}</Text>
            </Flex>
            <Flex horizontalAlignment="right">
              <ButtonGhost
                onClick={() => {
                  if (dispatch) {
                    dispatch({ type: 'RESET_FILTER' });
                  }
                  onViewOptionsChange?.([{ name: 'search-text', value: '' }]);
                }}
              >
                {translationAnyText(t, 'ButtonResetFilters')}
              </ButtonGhost>
            </Flex>
          </Box.Item>
        </Box>
      </Flex>
    </div>
  );
};
