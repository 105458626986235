export default {
  Entities: [
    {
      Properties: {
        FilterViewId: '0',
        Name: 'MyActiveProjects',
        IsDefault: true,
        Created: '2024-9-19T17:15:33.008Z',
        LastModified: '2024-9-19T17:15:33.008Z',
        FilterValues: [
          {
            Name: 'ProjectManager',
            Value: [
              {
                Key: 'Ang Kian Leong',
                Value: '1110',
              },
            ],
            IsInclusive: true,
            FilterType: 'MultiSelectTypeSearch',
          },
          {
            Name: 'ProjectStatus',
            Value: [
              {
                Key: 'Active projects',
                Value: '-1',
              },
            ],
            IsInclusive: true,
            FilterType: 'MultiSelectTypeSearch',
          },
          {
            Name: 'HideUnmovableRegistrations',
            Value: [
              {
                Key: null,
                Value: 'true',
              },
            ],
            IsInclusive: true,
            FilterType: 'Checkbox',
          },
          {
            Name: 'DateRange',
            // Return as Fix period or dataRage 'CurrentWeek' or 'LastWeek' or 'LastMonth'
            Value: 'CurrentWeek',
            // Value: '2024-01-30,2024-11-12',
            IsInclusive: true,
            FilterType: 'DateRange',
          },
        ],
      },
    },
    {
      Properties: {
        FilterViewId: '1',
        Name: 'New View',
        IsDefault: false,
        Created: '2024-9-19T17:15:33.008Z',
        LastModified: '2024-9-19T17:15:33.008Z',
        FilterValues: [
          {
            Name: 'ProjectManager',
            Value: [
              {
                Key: 'Chow Mun Leong',
                Value: '1111',
              },
            ],
            IsInclusive: true,
            FilterType: 'MultiSelectTypeSearch',
          },
          {
            Name: 'ProjectStatus',
            Value: [
              {
                Key: 'Inactive projects',
                Value: '1',
              },
            ],
            IsInclusive: true,
            FilterType: 'MultiSelectTypeSearch',
          },
          // {
          //   Name: 'DateRange',
          //   Value: {
          //     PeriodStartsAt: '2024-10-09T00:15:33.008Z',
          //     PeriodEndsAt: '2024-10-16T00:15:33.008Z',
          //   },
          //   IsInclusive: true,
          //   FilterType: 'DateRange',
          // },
          // {
          //   Name: 'SearchText',
          //   Value: 'test',
          //   IsInclusive: true,
          //   FilterType: 'Textbox',
          // },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filter-view',
      Rel: 'self',
    },
  ],
};
