import {
  addMonths,
  addQuarters,
  addWeeks,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
} from 'date-fns';
import { TPeriod } from 'src/apis/savedViewAPI/savedViewAPI';

export const getEndDateWithPeriod = (period?: TPeriod) => {
  switch (period) {
    case 'this_week':
      return endOfWeek(new Date(), { weekStartsOn: 1 });

    case 'this_and_next_week':
      return endOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 });

    case 'this_month':
      return endOfMonth(new Date());

    case 'this_and_next_month':
      return endOfMonth(addMonths(new Date(), 1));

    case 'this_quarter':
      return endOfQuarter(new Date());

    case 'this_and_next_quarter':
      return endOfQuarter(addQuarters(new Date(), 1));

    case 'this_year':
      return endOfYear(new Date());

    case 'this_and_next_three_weeks':
    default:
      return endOfWeek(addWeeks(new Date(), 3), { weekStartsOn: 1 });
  }
};
