import { TViewOptionsChangeParameters } from 'src/components/layout/FilterLayout/types';
import { addYears, format } from 'date-fns';
import {
  MonthPicker,
  MonthPickerDates,
  MonthPickerProps,
} from 'src/components/ui-components/MonthPicker';
import { useGetDateLocale } from 'src/utils/locale/dateLocale';

interface PeriodPickerProps extends Pick<MonthPickerProps, 'setMonthRange'> {
  initialMonthRange: MonthPickerDates;
  onChange?: (item: TViewOptionsChangeParameters[]) => void;
}

export const PeriodPicker = ({
  initialMonthRange,
  setMonthRange,
  onChange,
  ...props
}: PeriodPickerProps) => {
  const userDateLocale = useGetDateLocale();

  const handlePeriodSelect = () => {
    if (initialMonthRange?.from && initialMonthRange?.to) {
      onChange?.([
        {
          name: 'period-starts-at',
          value: format(initialMonthRange.from, 'yyyy-MM-dd'),
        },
        { name: 'period-ends-at', value: format(initialMonthRange.to, 'yyyy-MM-dd') },
      ]);
    }
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const futureYear = addYears(currentDate, 3).getFullYear();

  return (
    <MonthPicker
      monthRange={initialMonthRange}
      userLocale={userDateLocale}
      setMonthRange={setMonthRange}
      onApply={handlePeriodSelect}
      startYear={currentYear}
      endYear={futureYear}
      width={225}
      {...props}
    />
  );
};
