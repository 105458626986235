import { format, subDays } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { useGetSavedView } from 'src/apis/savedViewAPI';
import { FilterPanelLayout } from 'src/components/layout/FilterPanelLayout';
import { FilterSection } from 'src/components/layout/FilterPanelLayout/components/MainContainer/components/FilterSection';
import {
  formatDateRange,
  handleMatchingOption,
  isValidDateRange,
} from 'src/components/layout/FilterPanelLayout/components/SaveViewContainer/utils';
import {
  IOptionsChangeParameters,
  TViewOptions,
} from 'src/components/layout/FilterPanelLayout/types';
import { Deck } from 'src/components/ui-components';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { useGetDateLocale } from 'src/utils/locale/dateLocale';
import { PeriodPicker } from '../ResourcePlanner/components/PeriodPicker';
import { Table } from './components/Table';
import { MHViewOptionsStateKey } from './localStorageKeys';

const defaultStartDate = subDays(new Date(), 7);
const defaultEndDate = subDays(new Date(), 0);

export const MoveHours = () => {
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { t: tDayPicker } = useTranslation('dayPicker');
  const userDateLocale = useGetDateLocale();

  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);
  const { data } = useGetSavedView(pageIdentifier);
  const savedView = useMemo(() => data || [], [data]);
  const [activeView, setActiveView] = useState<string>('');

  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptions | undefined>(undefined);

  // is pre-defined period - CurrentWeek, PastOneWeek, PastOneMonth
  const [preDefinedPeriod, setPreDefinedPeriod] = useState<string>('');
  const [initialDateStart, setInitialDateStart] = useState(defaultStartDate);
  const [initialDateEnd, setInitialDateEnd] = useState(defaultEndDate);

  useEffect(() => {
    // Reset activeView to an empty string when the component mounts
    setActiveView('');
  }, []);

  const setViewOptions = () => {
    const viewOptions = savedView.find((v) => v.filterViewId === activeView)?.viewOptions;
    // Format date-range to period-starts-at and period-ends-at based on CurrentWeek, PastOneWeek, PastOneMonth or fix period
    if (viewOptions?.['date-range']) {
      const dateRange = viewOptions['date-range'];
      delete viewOptions['date-range'];

      if (isValidDateRange(dateRange)) {
        setPreDefinedPeriod('');
        formatDateRange(dateRange, viewOptions, setChangedViewOptions);
      } else {
        setPreDefinedPeriod(dateRange);
        handleMatchingOption(
          dateRange,
          viewOptions,
          setChangedViewOptions,
          initialDateStart,
          userDateLocale,
          tDayPicker,
        );
      }
    } else {
      setChangedViewOptions({
        ...viewOptions,
        'period-starts-at': format(defaultStartDate, 'yyyy-MM-dd'),
        'period-ends-at': format(defaultEndDate, 'yyyy-MM-dd'),
      });
    }
  };

  useEffect(() => {
    setViewOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView]);

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    if (changedViewOptions?.['period-starts-at']) {
      setInitialDateStart(new Date(changedViewOptions['period-starts-at']));
    } else {
      setInitialDateStart(defaultStartDate);
    }
    if (changedViewOptions?.['period-ends-at']) {
      setInitialDateEnd(new Date(changedViewOptions['period-ends-at']));
    } else {
      setInitialDateEnd(defaultEndDate);
    }
    localStorage.setItem(MHViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  const onViewOptionsChange = (optionItems: IOptionsChangeParameters[]) => {
    const options = optionItems.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...options);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  // To fix retrieve previous pageIdentifier from store
  if (pageIdentifier !== 'DesignSevenMoveHours') {
    return null;
  }

  return (
    <ResponseHandler isLoading={isLoading} isError={isError} isEmpty={filterList.length <= 0}>
      <FilterPanelLayout
        filterList={filterList}
        savedViewsList={savedView}
        activeView={activeView}
        setActiveView={setActiveView}
        changedViewOptions={changedViewOptions}
        preDefinedPeriod={preDefinedPeriod}
        setViewOptions={setViewOptions}
        key="MoveHoursFilterPanelLayout"
      >
        <Deck.Item>
          <Table
            selectedViewOptions={changedViewOptions}
            onViewOptionsChange={onViewOptionsChange}
            filterSection={
              <FilterSection
                changedViewOptions={changedViewOptions}
                onViewOptionsChange={onViewOptionsChange}
                key="MoveHoursFilterSection"
                viewOptionsLeftFilter={
                  <PeriodPicker
                    initialDateStart={initialDateStart}
                    initialDateEnd={initialDateEnd}
                    onChange={onViewOptionsChange}
                    preSelectOptions="pastPreSelectOptions"
                    setPreDefinedPeriod={setPreDefinedPeriod}
                  />
                }
              />
            }
          />
        </Deck.Item>
      </FilterPanelLayout>
    </ResponseHandler>
  );
};
